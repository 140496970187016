import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-csv-import-rules',
  templateUrl: './csv-import-rules.page.html',
  styleUrls: ['./csv-import-rules.page.scss'],
})
export class CsvImportRulesPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
