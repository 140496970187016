export const environment = {
    production: false,
    firebase: {
    apiKey: "AIzaSyDZZ7WjY13dkLC3-KWkJal9ui7g-TGlWwQ",
    authDomain: "krishi-kiran.firebaseapp.com",
    projectId: "krishi-kiran",
    databaseURL: "https://krishi-kiran.firebaseio.com",
    storageBucket: "krishi-kiran.appspot.com",
    messagingSenderId: "361491884471",
    appId: "1:361491884471:web:226a3365d3192c57f0e032",
    measurementId: "G-TXCV3P67WK",
    },
    shopProductsLimit: 50,
    timeToUpdateAgentLocation: 30000,
    deliveryBikeurl: 'assets/img/delivery-bike.png',
    destinationMarkerUrl: 'assets/img/map-marker.png',
    razorpay: {
      description: "Krishi Kiran Payment",
      currency: 'INR',
      image: 'https:///assets/img/admin-pic.png',
      theme: {
        color: '#215733'
      }
    },
    paytm: {
      CHANNEL_ID: 'WAP',
      WEBSITE: 'DEFAULT',
      INDUSTRY_TYPE_ID: 'Retail',
      ENVIRONMENT: 'production'
    },
    envPaytmActive: true,
    storeName: "Krishi Kiran",
    supportPhone: '1234567890',
    scrollLimit:20,
    priceReqFeature:false,
    appSharingImg:'https:///assets/img/admin-pic.png',
    deliveryFeature: false,
    ALGOLIA_APP_ID: 'DEYL6OZL2B',
    isDeliveryBasedKm: false,
    orderIdPrefix: 'ORD',
    defaultCountryCode: '+91',
    currencyCode: 'INR',
    taxType: 'GST',
    socialSigin: false,
    defaultProductView: 'list',
    webClientId:'',
    productSharing: true,
    phoneLength: 10,
    showFooterLogo: true,
    openAlgoliaSearch:false,
    LOGGLY_API_KEY: 'dd80a702-e3cf-4633-9d38-5ee92fe68193',
    priceForRetail: false,
    referralFeature: false,
    subscriptionFeature: false,
    RFQFeature: false,
    usersScrollLimit: 20,
    limitedTimeDeal: false,
    productRatings: false,
    multiRegion: false,
    multiVendor: false,
    membership: false,
    isDeliveryBasedOnWeight: false,
    shiprocket: false,
    pageList: [{name: 'Orders',path:'admin-orders'},{name:'Products',path:'admin-products'},{name:'Messages',path:'admin-home'},{name:'Homepage',path:'homepage-setting'},{name:'Users',path:'admin-allusers'},{name:'Categories',path:'admin-categories'},{name:'Brands',path:'all-brands'},{name:'Service Requests',path:'service-requests'},{name:'Manage Services',path:'all-services'},{name:'Out of Stock Products',path:'dashboard'},
    {name:'Coupon Codes',path:'coupon-codes'},{name:'Promo Popup',path:'promo-popup-settings'},{name:'Extra Features',path:'feature-list'},{name:'Delivery Settings',path:'delivery-settings'},{name:'Payment Settings',path:'admin-payment-settings'},{name:'Admin Settings',path:'admin-settings'},{name:'Wallet Settings',path:'wallet-settings'},{name:'Referral Settings',path:'referral-settings'},{name:'Price Requests',path:'price-requests'},
    {name:'Reports',path:'sales-report'},{name:'Offers',path:'offer-settings'},{name:'Languages',path:'language-current'},{name:'Multi Region',path:'multi-region-all'},{name:'Multi Vendor',path:'multi-vendor-all'},{name:'Subscriptions',path:'subscriptions'},{name:'Filters',path:'all-filters'},{name:'Membership',path:'membership-settings'},{name:'Shiprocket Settings',path:'shiprocket-settings'},{name:'About Page',path:'about-setting'},
    {name:'Website Seo',path:'website-seo'},{name:'Feedback',path:'all-feedbacks'},{name:'App Usage',path:'app-usage'},{name:'Terms & Privacy',path:'admin-terms-settings'},{name:'Banners (deprecated)',path:'admin-banners'},{name:'Promo Video (deprecated)',path:'promo-video-settings'},{name:'Best Sellers (deprecated)',path:'admin-best-sellers'}],
    createUserOrder: false,
    broadcastLimit: 24,
    lockSystem: false,
    resellingFeature: false,
    vendorsLimit: 10,
    allOrderStatuses: ['Pending', 'Rejected', 'Confirmed', 'Cancelled', 'Dispatched', 'Delivered', 'Returned']
  };