import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ProductTypeComponent } from './admin/admin-shop/new-product/product-type/product-type.component';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { DesignStudioComponent } from './admin/design-studio/design-studio.component';
import { AppointmentOrdersComponent } from './components/appointment-orders/appointment-orders.component';
import { AppointmentSettingsComponent } from './components/appointment-settings/appointment-settings.component';


const routes: Routes = [
   { path: '', redirectTo: 'admin-orders', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'verify-otp', loadChildren: './home/verify-otp/verify-otp.module#VerifyOtpPageModule' },
  { path: 'your-identity', loadChildren: './home/your-identity/your-identity.module#YourIdentityPageModule' },
  { path: 'chat-bot', loadChildren: './chat-bot/chat-bot.module#ChatBotPageModule' },
  { path: 'product-details', loadChildren: './product-details/product-details.module#ProductDetailsPageModule' },
  { path: 'shop', loadChildren: './shop/shop.module#ShopPageModule' },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' },
  { path: 'new-product', loadChildren: './admin/admin-shop/new-product/new-product.module#NewProductPageModule' },
  { path: 'broadcast-msg', loadChildren: './admin/admin-home/broadcast-msg/broadcast-msg.module#BroadcastMsgPageModule' },
  { path: 'admin-chat', loadChildren: './admin/admin-home/admin-chat/admin-chat.module#AdminChatPageModule' },
  { path: 'order-details', loadChildren: './admin/admin-shop/all-orders/order-details/order-details.module#OrderDetailsPageModule' },
  { path: 'categories', loadChildren: './admin/admin-shop/categories/categories.module#CategoriesPageModule' },
  { path: 'shop-categories', loadChildren: './shop/shop-categories/shop-categories.module#ShopCategoriesPageModule' },
  { path: 'image-modal', loadChildren: './image-modal/image-modal.module#ImageModalPageModule' },
  { path: 'sidemenu', loadChildren: './sidemenu/sidemenu.module#SidemenuPageModule' },
  { path: 'admin-home', loadChildren: './admin/admin-home/admin-home.module#AdminHomePageModule'},
  { path: 'admin-shop', loadChildren: './admin/admin-shop/admin-shop.module#AdminShopPageModule' },
  { path: 'admin-settings', loadChildren: './admin/admin-settings/admin-settings.module#AdminSettingsPageModule' },
  { path: 'admin-allusers', loadChildren: './admin/admin-allusers/admin-allusers.module#AdminAllusersPageModule' },
  { path: 'admin-orders', loadChildren: './admin/admin-orders/admin-orders.module#AdminOrdersPageModule' },
  { path: 'admin-categories', loadChildren: './admin/admin-categories/admin-categories.module#AdminCategoriesPageModule' },
  { path: 'admin-products', loadChildren: './admin/admin-products/admin-products.module#AdminProductsPageModule' },
  { path: 'user-addresses', loadChildren: './user-addresses/user-addresses.module#UserAddressesPageModule' },
  { path: 'new-address', loadChildren: './new-address/new-address.module#NewAddressPageModule' },
  { path: 'user-cart', loadChildren: './user-cart/user-cart.module#UserCartPageModule' },
  { path: 'order-summary', loadChildren: './order-summary/order-summary.module#OrderSummaryPageModule' },
  { path: 'select-address', loadChildren: './select-address/select-address.module#SelectAddressPageModule' },
  { path: 'user-order-details', loadChildren: './user-order-details/user-order-details.module#UserOrderDetailsPageModule' },
  { path: 'user-order-history', loadChildren: './user-order-history/user-order-history.module#UserOrderHistoryPageModule' },
  { path: 'order-payment', loadChildren: './order-payment/order-payment.module#OrderPaymentPageModule' },
  { path: 'terms-conditions', loadChildren: './terms-conditions/terms-conditions.module#TermsConditionsPageModule' },
  { path: 'location-map', loadChildren: './location-map/location-map.module#LocationMapPageModule' },
  { path: 'search-items', loadChildren: './search-items/search-items.module#SearchItemsPageModule' },
  { path: 'delivery-orders', loadChildren: './delivery/delivery-orders/delivery-orders.module#DeliveryOrdersPageModule' },
  { path: 'delivery-order-details', loadChildren: './delivery/delivery-order-details/delivery-order-details.module#DeliveryOrderDetailsPageModule' },
  { path: 'delivery-navigation', loadChildren: './delivery/delivery-navigation/delivery-navigation.module#DeliveryNavigationPageModule' },
  { path: 'admin-payment-settings', loadChildren: './admin/admin-payment-settings/admin-payment-settings.module#AdminPaymentSettingsPageModule' },
  { path: 'admin-terms-settings', loadChildren: './admin/admin-terms-settings/admin-terms-settings.module#AdminTermsSettingsPageModule' },
  { path: 'terms-privacy', loadChildren: './terms-privacy/terms-privacy.module#TermsPrivacyPageModule' },
  { path: 'admin-banners', loadChildren: './admin/admin-banners/admin-banners.module#AdminBannersPageModule' },
  { path: 'admin-best-sellers', loadChildren: './admin/admin-best-sellers/admin-best-sellers.module#AdminBestSellersPageModule' },
  { path: 'best-sellers-modal', loadChildren: './admin/best-sellers-modal/best-sellers-modal.module#BestSellersModalPageModule' },
  { path: 'pricelist-modal', loadChildren: './pricelist-modal/pricelist-modal.module#PricelistModalPageModule' },
  { path: 'coupon-codes', loadChildren: './admin/coupon-codes/coupon-codes.module#CouponCodesPageModule' },
  { path: 'support', loadChildren: './admin/support/support.module#SupportPageModule' },
  { path: 'add-coupon-codes', loadChildren: './admin/add-coupon-codes/add-coupon-codes.module#AddCouponCodesPageModule' },
  { path: 'delivery-settings', loadChildren: './admin/delivery-settings/delivery-settings.module#DeliverySettingsPageModule' },
  { path: 'coupon-code-modal', loadChildren: './admin/coupon-code-modal/coupon-code-modal.module#CouponCodeModalPageModule' },
  { path: 'add-subcategories', loadChildren: './admin/add-subcategories/add-subcategories.module#AddSubcategoriesPageModule' },
  { path: 'shop-subcategories', loadChildren: './shop/shop-subcategories/shop-subcategories.module#ShopSubcategoriesPageModule' },
  { path: 'buynow-pricelist-modal', loadChildren: './buynow-pricelist-modal/buynow-pricelist-modal.module#BuynowPricelistModalPageModule' },
  { path: 'auto-confirm-payment', loadChildren: './auto-confirm-payment/auto-confirm-payment.module#AutoConfirmPaymentPageModule' },
  { path: 'no-user-access', loadChildren: './no-user-access/no-user-access.module#NoUserAccessPageModule' },
  { path: 'homepage', loadChildren: './homepage/homepage.module#HomepagePageModule' },
  { path: 'colors-modal', loadChildren: './admin/variants/colors-modal/colors-modal.module#ColorsModalPageModule' },
  { path: 'templates-modal', loadChildren: './admin/variants/templates-modal/templates-modal.module#TemplatesModalPageModule' },
  { path: 'admin-products-options', loadChildren: './admin/admin-products-options/admin-products-options.module#AdminProductsOptionsPageModule' },
  { path: 'price-requests', loadChildren: './admin/price-requests/price-requests.module#PriceRequestsPageModule' },
  { path: 'states-modal', loadChildren: './states-modal/states-modal.module#StatesModalPageModule' },
  { path: 'wallet-settings', loadChildren: './admin/wallet-settings/wallet-settings.module#WalletSettingsPageModule' },
  { path: 'admin-allusers-details', loadChildren: './admin/admin-allusers-details/admin-allusers-details.module#AdminAllusersDetailsPageModule' },
  { path: 'offer-settings', loadChildren: './admin/offer-settings/offer-settings.module#OfferSettingsPageModule' },
  { path: 'offer-create', loadChildren: './admin/offer-create/offer-create.module#OfferCreatePageModule' },
  { path: 'all-offers', loadChildren: './all-offers/all-offers.module#AllOffersPageModule' },
  { path: 'add-brand', loadChildren: './admin/admin-brands/add-brand/add-brand.module#AddBrandPageModule' },
  { path: 'all-brands', loadChildren: './admin/admin-brands/all-brands/all-brands.module#AllBrandsPageModule' },
  { path: 'service-requests', loadChildren: './admin/admin-services/service-requests/service-requests.module#ServiceRequestsPageModule' },
  { path: 'create-service', loadChildren: './admin/admin-services/create-service/create-service.module#CreateServicePageModule' },
  { path: 'all-services', loadChildren: './admin/admin-services/all-services/all-services.module#AllServicesPageModule' },
  { path: 'request-complete', loadChildren: './admin/admin-services/request-complete/request-complete.module#RequestCompletePageModule' },
  // { path: 'app-usage', loadChildren: './admin/app-usage/app-usage.module#AppUsagePageModule' },
  { path: 'all-feedbacks', loadChildren: './admin/all-feedbacks/all-feedbacks.module#AllFeedbacksPageModule' },
  { path: 'feedback-details', loadChildren: './admin/feedback-details/feedback-details.module#FeedbackDetailsPageModule' },
  { path: 'import-products', loadChildren: './admin/import-products/import-products.module#ImportProductsPageModule' },
  { path: 'sales-reports', loadChildren: './reports/sales-reports/sales-reports.module#SalesReportsPageModule' },
  { path: 'category-report', loadChildren: './reports/category-report/category-report.module#CategoryReportPageModule' },
  { path: 'products-report', loadChildren: './reports/products-report/products-report.module#ProductsReportPageModule' },
  { path: 'brands-report', loadChildren: './reports/brands-report/brands-report.module#BrandsReportPageModule' },
  { path: 'sales-report', loadChildren: './reports/sales-report/sales-report.module#SalesReportPageModule' },
  { path: 'users-report', loadChildren: './reports/users-report/users-report.module#UsersReportPageModule' },
  { path: 'tax-report', loadChildren: './reports/tax-report/tax-report.module#TaxReportPageModule' },
  { path: 'services-report', loadChildren: './reports/services-report/services-report.module#ServicesReportPageModule' },
  { path: 'manage-roles', loadChildren: './manage-roles/manage-roles.module#ManageRolesPageModule' },
  { path: 'website-seo', loadChildren: './website-seo/website-seo.module#WebsiteSeoPageModule' },
  { path: 'about-setting', loadChildren: './about-setting/about-setting.module#AboutSettingPageModule' },
  { path: 'banner-settings', loadChildren: './admin/admin-banners/banner-settings/banner-settings.module#BannerSettingsPageModule' },
  { path: 'banner-linking-modal', loadChildren: './admin/admin-banners/banner-linking-modal/banner-linking-modal.module#BannerLinkingModalPageModule' },
  { path: 'promo-popup-settings', loadChildren: './admin/promo-popup-settings/promo-popup-settings.module#PromoPopupSettingsPageModule' },
  // { path: 'area-report', loadChildren: './reports/area-report/area-report.module#AreaReportPageModule' },
  { path: 'manage-shipment/:order_id', loadChildren: './admin/manage-shipment/manage-shipment.module#ManageShipmentPageModule' },
  { path: 'manage-shipment/:order_id/:vendor_id', loadChildren: './admin/manage-shipment/manage-shipment.module#ManageShipmentPageModule' },


  { path: 'area-report', loadChildren: './reports/area-report/area-report.module#AreaReportPageModule' },
  { path: 'pages-setting', loadChildren: './pages/pages-setting/pages-setting.module#PagesSettingPageModule' },
  { path: 'widgets-banner-slider', loadChildren: './pages/widgets/widgets-banner-slider/widgets-banner-slider.module#WidgetsBannerSliderPageModule' },
  { path: 'widget-type-modal', loadChildren: './pages/widgets/widget-type-modal/widget-type-modal.module#WidgetTypeModalPageModule' },
  { path: 'banners-list', loadChildren: './pages/widgets/widget-banner-slider/banners-list/banners-list.module#BannersListPageModule' },
  { path: 'edit-banner', loadChildren: './pages/widgets/widget-banner-slider/edit-banner/edit-banner.module#EditBannerPageModule' },
  { path: 'banner-slider-widgets-list', loadChildren: './pages/widgets/widget-banner-slider/banner-slider-widgets-list/banner-slider-widgets-list.module#BannerSliderWidgetsListPageModule' },
  { path: 'widgets-list', loadChildren: './pages/widgets/widgets-list/widgets-list.module#WidgetsListPageModule' },
  { path: 'edit-slide', loadChildren: './pages/widgets/widget-banner-slider/edit-slide/edit-slide.module#EditSlidePageModule' },
  { path: 'image-banners-list', loadChildren: './pages/widgets/widget-image-banner/image-banners-list/image-banners-list.module#ImageBannersListPageModule' },
  { path: 'edit-image-banner', loadChildren: './pages/widgets/widget-image-banner/edit-image-banner/edit-image-banner.module#EditImageBannerPageModule' },
  { path: 'product-carousel-list', loadChildren: './pages/widgets/product-carousel/product-carousel-list/product-carousel-list.module#ProductCarouselListPageModule' },
  { path: 'edit-product-carousel', loadChildren: './pages/widgets/product-carousel/edit-product-carousel/edit-product-carousel.module#EditProductCarouselPageModule' },
  { path: 'product-list-modal', loadChildren: './pages/widgets/product-list-modal/product-list-modal.module#ProductListModalPageModule' },
  { path: 'homepage-setting', loadChildren: './pages/homepage-setting/homepage-setting.module#HomepageSettingPageModule' },
  { path: 'select-widget-modal', loadChildren: './pages/homepage-setting/select-widget-modal/select-widget-modal.module#SelectWidgetModalPageModule' },
  { path: 'video-block-list', loadChildren: './pages/widgets/video-block/video-block-list/video-block-list.module#VideoBlockListPageModule' },
  { path: 'edit-video-block', loadChildren: './pages/widgets/video-block/edit-video-block/edit-video-block.module#EditVideoBlockPageModule' },
  { path: 'edit-image-block', loadChildren: './pages/widgets/image-block/edit-image-block/edit-image-block.module#EditImageBlockPageModule' },
  { path: 'image-block-list', loadChildren: './pages/widgets/image-block/image-block-list/image-block-list.module#ImageBlockListPageModule' },
  { path: 'text-block-list', loadChildren: './pages/widgets/text-block/text-block-list/text-block-list.module#TextBlockListPageModule' },
  { path: 'edit-text-block', loadChildren: './pages/widgets/text-block/edit-text-block/edit-text-block.module#EditTextBlockPageModule' },
  { path: 'language-current', loadChildren: './admin/language-settings/language-current/language-current.module#LanguageCurrentPageModule' },
  { path: 'language-add', loadChildren: './admin/language-settings/language-add/language-add.module#LanguageAddPageModule' },
  { path: 'referral-settings', loadChildren: './admin/referral-settings/referral-settings.module#ReferralSettingsPageModule' },
  { path: 'multi-region-add', loadChildren: './admin/multi-region/multi-region-add/multi-region-add.module#MultiRegionAddPageModule' },
  { path: 'multi-region-all', loadChildren: './admin/multi-region/multi-region-all/multi-region-all.module#MultiRegionAllPageModule' },
  { path: 'multi-vendor-all', loadChildren: './admin/multi-vendor/multi-vendor-all/multi-vendor-all.module#MultiVendorAllPageModule' },
  { path: 'multi-vendor-add', loadChildren: './admin/multi-vendor/multi-vendor-add/multi-vendor-add.module#MultiVendorAddPageModule' },
  { path: 'edit-widget', loadChildren: './pages/homepage-setting/edit-widget/edit-widget.module#EditWidgetPageModule' },
  { path: 'subscriptions', loadChildren: './admin/subscriptions/subscriptions.module#SubscriptionsPageModule' },
  { path: 'all-filters', loadChildren: './admin/filter-settings/all-filters/all-filters.module#AllFiltersPageModule' },
  { path: 'add-filter', loadChildren: './admin/filter-settings/add-filter/add-filter.module#AddFilterPageModule' },
  { path: 'select-categories', loadChildren: './admin/select-categories/select-categories.module#SelectCategoriesPageModule' },
  { path: 'select-filter', loadChildren: './admin/filter-settings/select-filter/select-filter.module#SelectFilterPageModule' },
  { path: 'membership-settings', loadChildren: './admin/membership-settings/membership-settings.module#MembershipSettingsPageModule' },
  { path: 'rating-approval', loadChildren: './admin/rating-approval/rating-approval.module#RatingApprovalPageModule' },
  { path: 'dashboard', loadChildren: './admin/dashboard/dashboard.module#DashboardPageModule' },
  { path: 'sample-homepage', loadChildren: './admin/sample-homepage/sample-homepage.module#SampleHomepagePageModule' },
  { path: 'create-order', loadChildren: './admin/admin-orders/create-order/create-order.module#CreateOrderPageModule' },
  { path: 'managers', loadChildren: './admin/managers/managers.module#ManagersPageModule' },
  { path: 'manager-edit', loadChildren: './admin/managers/manager-edit/manager-edit.module#ManagerEditPageModule' },
  { path: 'product-variant', loadChildren: './admin/admin-orders/create-order/product-variant/product-variant.module#ProductVariantPageModule' },
  { path: 'email-sms', loadChildren: './admin/email-sms/email-sms.module#EmailSmsPageModule' },
  { path: 'edit-categories', loadChildren: './pages/widgets/edit-categories/edit-categories.module#EditCategoriesPageModule' },
  { path: 'edit-brands', loadChildren: './pages/widgets/edit-brands/edit-brands.module#EditBrandsPageModule' },
  { path: 'edit-services', loadChildren: './pages/widgets/edit-services/edit-services.module#EditServicesPageModule' },
  { path: 'vendor-products', loadChildren: './admin/multi-vendor/vendor-products/vendor-products.module#VendorProductsPageModule' },
  { path: 'payment-gateway-settings', loadChildren: './admin/admin-payment-settings/payment-gateway-settings/payment-gateway-settings.module#PaymentGatewaySettingsPageModule' },
  { path: 'delivery-partner-settings', loadChildren: './admin/delivery-settings/delivery-partner-settings/delivery-partner-settings.module#DeliveryPartnerSettingsPageModule' },
  { path: 'create-subscription', loadChildren: './admin/admin-orders/create-order/create-subscription/create-subscription.module#CreateSubscriptionPageModule' },
  { path: 'users-modal', loadChildren: './admin/users-modal/users-modal.module#UsersModalPageModule' },
  { path: 'edit-form', loadChildren: './pages/widgets/edit-form/edit-form.module#EditFormPageModule' },
  { path: 'pincodes-modal', loadChildren: './pincodes-modal/pincodes-modal.module#PincodesModalPageModule' },
  { path: 'admin-order-popover', loadChildren: './admin/admin-orders/admin-order-popover/admin-order-popover.module#AdminOrderPopoverPageModule' },
  { path: 'archived-orders', loadChildren: './admin/archived-orders/archived-orders.module#ArchivedOrdersPageModule' },
  { path: 'add-input', loadChildren: './pages/widgets/edit-form/add-input/add-input.module#AddInputPageModule' },
  { path: 'area-modal', loadChildren: './admin/delivery-settings/area-modal/area-modal.module#AreaModalPageModule' },
  { path: 'plan-payments', loadChildren: './admin/plan-payments/plan-payments.module#PlanPaymentsPageModule' },
  { path: 'add-ons', loadChildren: './admin/plan-payments/add-ons/add-ons.module#AddOnsPageModule' },
  { path: 'complete-payment', loadChildren: './admin/plan-payments/complete-payment/complete-payment.module#CompletePaymentPageModule' },
  { path: 'vendor-report', loadChildren: './reports/vendor-report/vendor-report.module#VendorReportPageModule' },
  { path: 'coupon-report', loadChildren: './reports/coupon-report/coupon-report.module#CouponReportPageModule' },
  { path: 'resale-order', loadChildren: './admin/resale-order/resale-order.module#ResaleOrderPageModule' },
  { path: 'preview-form', loadChildren: './pages/widgets/edit-form/preview-form/preview-form.module#PreviewFormPageModule' },
  { path: 'vendor-registration', loadChildren: './vendor-registration/vendor-registration.module#VendorRegistrationPageModule' },
  { path: 'vendor-request-modal', loadChildren: './vendor-registration/vendor-request-modal/vendor-request-modal.module#VendorRequestModalPageModule' },
  { path: 'view-order', loadChildren: './admin/admin-home/view-order/view-order.module#ViewOrderPageModule' },
  { path: 'edit-order', loadChildren: './admin/admin-shop/all-orders/edit-order/edit-order.module#EditOrderPageModule' },
  { path: 'referral-report', loadChildren: './reports/referral-report/referral-report.module#ReferralReportPageModule' },
  { path: 'referred-users-modal', loadChildren: './reports/referral-report/referred-users-modal/referred-users-modal.module#ReferredUsersModalPageModule' },

  { path: 'cancelled-reason', loadChildren: './admin/admin-shop/all-orders/cancelled-reason/cancelled-reason.module#CancelledReasonPageModule' },
  { path: 'product-reviews', loadChildren: './product-reviews/product-reviews.module#ProductReviewsPageModule' },
  { path: 'product-review', loadChildren: './product-reviews/product-review/product-review.module#ProductReviewPageModule' },
  { path: 'add-vendor-modal', loadChildren: './admin/multi-vendor/multi-vendor-all/add-vendor-modal/add-vendor-modal.module#AddVendorModalPageModule' },
  { path: 'products-to-deliver', loadChildren: './admin/admin-orders/products-to-deliver/products-to-deliver.module#ProductsToDeliverPageModule' },
  { path: 'contact-settings', loadChildren: './contact-settings/contact-settings.module#ContactSettingsPageModule' },
  { path: 'free-products', loadChildren: './admin/free-products/free-products.module#FreeProductsPageModule' },
  { path: 'products-modal', loadChildren: './admin/products-modal/products-modal.module#ProductsModalPageModule' },
  { path: 'faq', loadChildren: './faq/faq.module#FaqPageModule' },
  { path: 'product-section', loadChildren: './admin/admin-shop/new-product/product-section/product-section.module#ProductSectionPageModule' },
  { path: 'vendor-orders', loadChildren: './vendor-orders/vendor-orders.module#VendorOrdersPageModule' },
  { path: 'vendor-order-details', loadChildren: './vendor-order-details/vendor-order-details.module#VendorOrderDetailsPageModule' },
  { path: 'vendor-settings', loadChildren: './vendor-settings/vendor-settings.module#VendorSettingsPageModule' },
  { path: 'retailers', loadChildren: './admin/retailers/retailers.module#RetailersPageModule' },
  { path: 'edit-document', loadChildren: './pages/widgets/edit-document/edit-document.module#EditDocumentPageModule' },
  { path: 'pickup-drop', loadChildren: './admin/pickup-drop/pickup-drop.module#PickupDropPageModule' },
  { path: 'form-submissions', loadChildren: './admin/form-submissions/form-submissions.module#FormSubmissionsPageModule' },
  { path: 'custom-code', loadChildren: './admin/custom-code/custom-code.module#CustomCodePageModule' },
  { path: 'quotation-chat', loadChildren: './admin/admin-orders/quotation-chat/quotation-chat.module#QuotationChatPageModule' },
  { path: 'quotation-edit', loadChildren: './admin/admin-orders/quotation-edit/quotation-edit.module#QuotationEditPageModule' },
  { path: 'edit-vendors', loadChildren: './pages/widgets/edit-vendors/edit-vendors.module#EditVendorsPageModule' },
  { path: 'design-studio', component: DesignStudioComponent },
  { path: 'vendor-settings-admin', loadChildren: './admin/vendor-settings/vendor-settings.module#VendorSettingsPageModule' },
  { path: 'appointment', component: AppointmentComponent},
  { path: 'product-type', component: ProductTypeComponent},
  { path: 'appointment-orders', component: AppointmentOrdersComponent},
  { path: 'appointment-settings', component: AppointmentSettingsComponent},
  { path: 'custom-language', loadChildren: './admin/custom-language/custom-language.module#CustomLanguagePageModule' },
  { path: 'admin-attributes', loadChildren: './admin/admin-attributes/admin-attributes/admin-attributes.module#AdminAttributesPageModule' },
  { path: 'invoices', loadChildren: './admin/admin-orders/invoices/invoices.module#InvoicesPageModule' },
  { path: 'filters', loadChildren: './admin/admin-orders/filters/filters.module#FiltersPageModule' },
  { path: 'all-support', loadChildren: './admin/all-support/all-support.module#AllSupportPageModule' },
  { path: 'add-issues-modal', loadChildren: './admin/add-issues-modal/add-issues-modal.module#AddIssuesModalPageModule' },
  { path: 'view-issues-modal', loadChildren: './admin/view-issues-modal/view-issues-modal.module#ViewIssuesModalPageModule' },
  // { path: 'all-support', loadChildren: './admin/all-support/all-support.module#AllSupportPageModule' },
  { path: 'video-help', loadChildren: './video-help/video-help/video-help.module#VideoHelpPageModule' },
  { path: 'integrations', loadChildren: './integrations/integrations.module#IntegrationsPageModule' },
  { path: 'integration-settings', loadChildren: './integrations/integration-settings/integration-settings.module#IntegrationSettingsPageModule' },

  { path: 'user-groups', loadChildren: './admin/user-groups/user-groups.module#UserGroupsPageModule' },
  { path: 'user-groups-modal', loadChildren: './admin/user-groups/user-groups-modal/user-groups-modal.module#UserGroupsModalPageModule' },
  { path: 'forms', loadChildren: './admin/forms/forms.module#FormsPageModule' },
  { path: 'multi-countries', loadChildren: './admin/multi-countries/multi-countries.module#MultiCountriesPageModule' },
  { path: 'delivery-agent-details', loadChildren: './admin/delivery-agent-details/delivery-agent-details.module#DeliveryAgentDetailsPageModule' },
  { path: 'booking', loadChildren: './admin/booking/booking.module#BookingPageModule' },
  { path: 'create-booking', loadChildren: './admin/booking/create-booking/create-booking.module#CreateBookingPageModule' },
  { path: 'product-addons', loadChildren: './admin/product-addons/product-addons.module#ProductAddonsPageModule' },
  { path: 'food-items', loadChildren: './admin/food-items/food-items.module#FoodItemsPageModule' },
  { path: 'create-food-item', loadChildren: './admin/food-items/create-food-item/create-food-item.module#CreateFoodItemPageModule' },
  { path: 'booking-orders', loadChildren: './admin/booking-orders/booking-orders.module#BookingOrdersPageModule' },
  { path: 'vouchers', loadChildren: './admin/vouchers/vouchers.module#VouchersPageModule' },
  { path: 'create-voucher', loadChildren: './admin/vouchers/create-voucher/create-voucher.module#CreateVoucherPageModule' },
  { path: 'add-sub-subcategories', loadChildren: './admin/add-sub-subcategories/add-sub-subcategories.module#AddSubSubcategoriesPageModule' },

  { path: 'whatsapp-promotions', loadChildren: './whatsapp-promotions/whatsapp-promotions.module#WhatsappPromotionsPageModule' },
  { path: 'change-booking-slot', loadChildren: './admin/booking-orders/change-booking-slot/change-booking-slot.module#ChangeBookingSlotPageModule' },
  { path: 'showcase', loadChildren: './admin/showcase/showcase.module#ShowcasePageModule' },
  { path: 'create-showcase', loadChildren: './admin/showcase/create-showcase/create-showcase.module#CreateShowcasePageModule' },
  { path: 'blogs-setting', loadChildren: './pages/blogs-setting/blogs-setting.module#BlogsSettingPageModule' },
  { path: 'import-export-manager', loadChildren: './admin/import-export-manager/import-export-manager.module#ImportExportManagerPageModule' },
  { path: 'product-csv-creator', loadChildren: './admin/import-export-manager/product-csv-creator/product-csv-creator.module#ProductCsvCreatorPageModule' },
  { path: 'product-csv-importer', loadChildren: './admin/import-export-manager/product-csv-importer/product-csv-importer.module#ProductCsvImporterPageModule' },
  { path: 'product-settings', loadChildren: './admin/product-settings/product-settings.module#ProductSettingsPageModule' },
  { path: 'product-image-importer', loadChildren: './admin/import-export-manager/product-image-importer/product-image-importer.module#ProductImageImporterPageModule' },
  { path: 'product-image-exporter', loadChildren: './admin/import-export-manager/product-image-exporter/product-image-exporter.module#ProductImageExporterPageModule' },
  { path: 'csv-import-rules', loadChildren: './admin/import-export-manager/csv-import-rules/csv-import-rules.module#CsvImportRulesPageModule' },
  { path: 'views-count-popover', loadChildren: './analytics/views-count-popover/views-count-popover.module#ViewsCountPopoverPageModule' },
  { path: 'vendor-membership', loadChildren: './vendor-membership/vendor-membership.module#VendorMembershipPageModule' },
  { path: 'buy-vendor-membership', loadChildren: './vendor-membership/buy-vendor-membership/buy-vendor-membership.module#BuyVendorMembershipPageModule' },
  { path: 'export-orders', loadChildren: './admin/import-export-manager/orders/export-orders/export-orders.module#ExportOrdersPageModule' },
  { path: 'import-orders', loadChildren: './admin/import-export-manager/orders/import-orders/import-orders.module#ImportOrdersPageModule' },
  { path: 'bulk-product-delete', loadChildren: './admin/import-export-manager/delete-bulk-products/delete-bulk-products.module#DeleteBulkProductsPageModule' },
  { path: 'subscription-calendar', loadChildren: './admin/subscription-calendar/subscription-calendar.module#SubscriptionCalendarPageModule' },
  { path: 'whatsapp-broadcast', loadChildren: './admin/whatsapp/whatsapp-broadcast/whatsapp-broadcast.module#WhatsappBroadcastPageModule' },
  { path: 'whatsapp-menu', loadChildren: './admin/whatsapp/whatsapp-menu/whatsapp-menu.module#WhatsappMenuPageModule' },
  { path: 'whatsapp-list', loadChildren: './admin/whatsapp/whatsapp-list/whatsapp-list.module#WhatsappListPageModule' },
  { path: 'whatsapp-settings', loadChildren: './admin/whatsapp/whatsapp-settings/whatsapp-settings.module#WhatsappSettingsPageModule' },
  { path: 'export-users', loadChildren: './admin/import-export-manager/users/export-users/export-users.module#ExportUsersPageModule' },
  { path: 'import-users', loadChildren: './admin/import-export-manager/users/import-users/import-users.module#ImportUsersPageModule' },
  { path: 'add-whatsapp-template', loadChildren: './admin/whatsapp/add-whatsapp-template/add-whatsapp-template.module#AddWhatsappTemplatePageModule' },
  { path: 'coupons-list', loadChildren: './admin/admin-orders/coupons-list/coupons-list.module#CouponsListPageModule' },
  { path: 'whatsapp-list-services', loadChildren: './admin/whatsapp/whatsapp-list-services/whatsapp-list-services.module#WhatsappListServicesPageModule' },
  { path: 'cart', loadChildren: './admin/users-cart/users-cart.module#UsersCartPageModule' },
  { path: 'user-cart-details-modal', loadChildren: './admin/user-cart-details-modal/user-cart-details-modal.module#UserCartDetailsModalPageModule' },






];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
